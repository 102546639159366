<template>
  <base-form-bottom path="home-orders-service-list">
    <div
      v-if="serviceTypeValue['value'] === SERVICE_TYPE_SEND_OUT_SERVICE"
      class="d-flex"
      style="gap: 8px"
    >
      <!--   Print Handler     -->
      <b-button
        :disabled="loadingFor.isLoading || disableByStateAndStatus(FORM_BUTTON_PRINT.action)"
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3"
        @click="printRFQForSupplierTemplate"
      >
        <Printer>
          <div
            id="print"
            hidden
          >
            <ServiceOrderPrintTemplate />
          </div>
        </Printer>
        <feather-icon icon="LPrintIcon" />
        {{ $t(FORM_BUTTON_PRINT.title) }}
      </b-button>
      <b-button
        v-for="({ variant, style, action, title }, idx) in formBottomActions"
        :key="idx"
        :variant="variant"
        class="font-medium-1 font-weight-bold d-flex justify-content-center align-center"
        :style="style"
        :disabled="loadingFor.isLoading || disableByStateAndStatus(action)"
        @click="submit(action)"
      >
        <b-spinner
          v-if="loadingFor.isLoading && loadingFor.action === action"
          small
          variant="secondary"
        />
        <span v-else>
          {{ $t(title) }}
        </span>
      </b-button>
    </div>
    <div
      v-if="serviceTypeValue['value'] === SERVICE_TYPE_IN_HOUSE_SERVICE"
      class="d-flex"
      style="gap: 8px"
    >
      <b-button
        v-for="({ variant, style, action, title }, idx) in inHouseFormBottomActions"
        :key="idx"
        :variant="variant"
        class="font-medium-1 font-weight-bold d-flex justify-content-center align-center"
        :style="style"
        :disabled="loadingFor.isLoading || disableByStateAndStatus(action)"
        @click="submit(action)"
      >
        <b-spinner
          v-if="loadingFor.isLoading && loadingFor.action === action"
          small
          variant="secondary"
        />
        <span v-else>
          {{ $t(title) }}
        </span>
      </b-button>
    </div>
  </base-form-bottom>

</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import jsonToFormData from '@/libs/jsonToFormData'
import BaseFormBottom from '@/views/main/orders/components/use-as-global/BaseFormBottom.vue'
import Printer from '@/views/components/Printer/Printer.vue'
import { assetStates } from '@/enum/asset-statuses'
import config from './config'
import mainConfig from '../../../config'
import ServiceOrderPrintTemplate from '../../common/print/ServiceOrderPrintTemplate.vue'

export default {
  name: 'FormBottom',
  components: {
    Printer,
    ServiceOrderPrintTemplate,
    BSpinner,
    BButton,
    BaseFormBottom,
  },
  props: {
    formRefs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters('service', ['getTotalDataOfForms']),
    serviceTypeValue() {
      return this.$store.state[this.MODULE_NAME].serviceType
    },
    order() {
      return this.$store.state[this.MODULE_NAME].order ?? {}
    },
    isOrderInDamaged() {
      return this.$store.state[this.MODULE_NAME].order.status === 1 && this.$store.state[this.MODULE_NAME].order.state === 2
    },
    // isServiceInHouseInDraft() {
    //   return this.order.service_type === this.SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE && this.order.status === 1 && this.order.state === 0
    // },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    disableByStateAndStatus(action) {
      if (action === this.FORM_BUTTON_SAVE_AS_DRAFT.action) {
        if (this.$router.currentRoute.name === 'request-service-quote-view' && this.order.state === 0 && this.order.status === 5) {
          return true
        }
        return this.order.state === assetStates.IN.code && this.order.status === 1
      }
      if (action === this.FORM_BUTTON_VOID.action || action === this.FORM_BUTTON_EMAIL_SUPPLIER.action || action === this.FORM_BUTTON_QUOTE_RECEIVED.action || action === this.FORM_BUTTON_PRINT.action) {
        if (this.$router.currentRoute.name === 'home-orders-request-service-quote') {
          return true
        }
        return this.order.state === assetStates.IN.code && (this.order.status === 5 || this.order.status === 0)
      }
    },
    redirectTo(id, action) {
      const actionOnEmailSupplierOrQuoteReceived = action === this.FORM_BUTTON_EMAIL_SUPPLIER.action ? this.FORM_BUTTON_EMAIL_SUPPLIER.action : this.FORM_BUTTON_QUOTE_RECEIVED.action
      const { serviceType: { value: serviceTypeValue } } = this.getTotalDataOfForms

      if (action === this.FORM_BUTTON_SAVE_AS_DRAFT.action) {
        if (serviceTypeValue === this.SERVICE_TYPE_SEND_OUT_SERVICE) {
          this.$router.push({ name: 'request-service-quote-view', params: { id } })
        }
        if (serviceTypeValue === this.SERVICE_TYPE_IN_HOUSE_SERVICE) {
          this.$router.push({ name: 'request-service-quote-in-house-view', params: { id } })
        }
      } else if (action === actionOnEmailSupplierOrQuoteReceived) {
        this.$router.push({ name: 'received-quote', params: { id } })
      } else if (action === this.FORM_BUTTON_VOID.action) {
        this.$router.push({ name: 'request-service-quote-view', params: { id } })
      } else {
        this.$router.push({ name: 'home-orders-service-list' })
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_RERENDER_COMPONENT_KEY`)
    },
    submit(action) {
      const { formRefs: { requestServiceQuoteForm } } = this
      if (action === this.FORM_BUTTON_SAVE_AS_DRAFT.action) {
        setTimeout(() => {
          requestServiceQuoteForm.reset()
        }, 0)
        setTimeout(() => {
          this.submitLogic(action)
        }, 1)
      } else if (action === this.FORM_BUTTON_VOID.action) {
        setTimeout(() => {
          requestServiceQuoteForm.reset()
        }, 0)
        setTimeout(() => {
          this.submitSendOutVoid(action)
        }, 1)
      } else {
        requestServiceQuoteForm.validate().then(success => {
          if (success) {
            this.submitLogic(action)
          } else {
            scrollToError(this, requestServiceQuoteForm)
          }
        })
      }
    },
    submitSendOutVoid(action) {
      const { order } = this
      const sendData = jsonToFormData({ id: order.id })
      this.sendNotification(this, sendData, `${this.MODULE_NAME}/send-out-void`)
        .then(() => {
          this.redirectTo(order.id, action)
          this.$emit('clear')
        })
        .catch(err => {
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
    submitLogic(action) {
      const { getTotalDataOfForms, order } = this
      const { serviceType: { value: serviceTypeValue }, assetItem } = getTotalDataOfForms
      this.loadingFor = {
        isLoading: true,
        action,
      }
      let mappedTotalDataToSend = {
        // id: order.id,
        id: assetStates.DAMAGED.code === assetItem.status ? assetItem.damaged.order_id : order.id,
        inventory_item_damaged_id: assetItem?.damaged?.id ? assetItem?.damaged?.id : assetItem?.id,
      }
      if (serviceTypeValue === this.SERVICE_TYPE_SEND_OUT_SERVICE) {
        mappedTotalDataToSend = this.mappingFields(Object.keys(this.fields), getTotalDataOfForms, {
          ...mappedTotalDataToSend,
          action,
          payment_term_id: getTotalDataOfForms.payment_term_id?.id,
          billing_contact_id: getTotalDataOfForms.billing_contact_id?.id,
          dispatch_warehouse_id: getTotalDataOfForms.dispatch_warehouse_id?.id,
          dispatch_method_id: getTotalDataOfForms.dispatch_method_id?.id,
          return_method_id: getTotalDataOfForms.return_method_id?.id,
          return_warehouse_id: getTotalDataOfForms.return_warehouse_id?.id,
          internal_shipping_notes: getTotalDataOfForms.internal_dispatch_notes,
          attachments: getTotalDataOfForms?.attachments?.filter(attachment => !attachment.id),
        })
      }

      if (serviceTypeValue === this.SERVICE_TYPE_IN_HOUSE_SERVICE) {
        mappedTotalDataToSend = {
          ...mappedTotalDataToSend,
          internal_order_reference: getTotalDataOfForms.internal_order_reference_in_house,
        }
      }

      // const convertedMappedDataToFormData = jsonToFormData(mappedTotalDataToSend)

      const dynamicState = serviceTypeValue === this.SERVICE_TYPE_SEND_OUT_SERVICE ? (order.state === 0 && order.status === 0 ? this.STATE_RFQ : this.STATE_SO) : this.STATE_IN_HOUSE_SERVICE

      const sendData = jsonToFormData({
        state: action === this.FORM_BUTTON_VOID.action ? this.STATE_RFQ : dynamicState,
        status: action === this.FORM_BUTTON_VOID.action ? this.STATUS_VOID : this.STATUS_IN_PROGRESS,
        ...mappedTotalDataToSend,
      })

      this.sendNotification(this, sendData, `${this.MODULE_NAME}/${serviceTypeValue}-update`)
        .then(resp => {
          const { data } = resp
          this.redirectTo(assetStates.DAMAGED.code === assetItem.status ? assetItem.damaged.order_id : data.data.id, action, order.inventory_item_damaged_id)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
        })
        .catch(err => {
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })

      // if (order.id) {
      //   const dynamicState = serviceTypeValue === this.SERVICE_TYPE_SEND_OUT_SERVICE ? this.STATE_SO : this.STATE_IN_HOUSE_SERVICE
      //
      //   const sendData = jsonToFormData({
      //     id: order.id,
      //     state: action === this.FORM_BUTTON_VOID.action ? this.STATE_RFQ : dynamicState,
      //     status: action === this.FORM_BUTTON_VOID.action ? this.STATUS_VOID : this.STATUS_IN_PROGRESS,
      //     ...mappedTotalDataToSend,
      //   })
      //
      //   this.sendNotification(this, sendData, `${this.MODULE_NAME}/${serviceTypeValue}-update`)
      //     .then(() => {
      //       this.redirectTo(order.id, action, order.inventory_item_damaged_id)
      //       this.$emit('clear')
      //     })
      //     .catch(err => {
      //       this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
      //     }).finally(() => {
      //       this.loadingFor = {
      //         isLoading: false,
      //         action: null,
      //       }
      //     })
      // } else {
      //   const dynamicURL = this.FORM_BUTTON_CREATE_SERVICE_ORDER.action === action ? `${this.MODULE_NAME}/create-service-in-house` : `${this.MODULE_NAME}/${serviceTypeValue}`
      //
      //   this.sendNotification(this, convertedMappedDataToFormData, dynamicURL)
      //     .then(({ data: { data } }) => {
      //       this.redirectTo(data[0].id, action, data[0].inventory_item_damaged.id)
      //       this.$emit('clear')
      //     })
      //     .catch(err => {
      //       this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
      //     }).finally(() => {
      //       this.loadingFor = {
      //         isLoading: false,
      //         action: null,
      //       }
      //     })
      // }
    },
    printRFQForSupplierTemplate() {
      this.$htmlToPaper('print', {
        autoClose: false,
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      FORM_BUTTON_VOID,
      FORM_BUTTON_PRINT,
      formBottomActions,
      dispatchAndReturnFields,
      inHouseFormBottomActions,
      FORM_BUTTON_SAVE_AS_DRAFT,
      FORM_BUTTON_EMAIL_SUPPLIER,
      FORM_BUTTON_QUOTE_RECEIVED,
      serviceOrderInformationFields,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      FORM_BUTTON_CREATE_SERVICE_ORDER,
      SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE,
    } = config()

    const {
      STATE_RFQ,
      STATE_SO,
      STATE_INVOICE,
      STATUS_NO_ACTION,
      STATUS_IN_PROGRESS,
      STATUS_ATTENTION,
      STATUS_WARNING,
      STATUS_READY,
      STATUS_VOID,
      STATE_IN_HOUSE_SERVICE,
    } = mainConfig()

    const fields = { ...dispatchAndReturnFields, ...serviceOrderInformationFields }

    return {
      fields,
      STATE_SO,
      STATE_RFQ,
      STATUS_VOID,
      MODULE_NAME,
      STATUS_READY,
      STATE_INVOICE,
      STATUS_WARNING,
      STATUS_ATTENTION,
      STATUS_NO_ACTION,
      FORM_BUTTON_VOID,
      FORM_BUTTON_PRINT,
      formBottomActions,
      STATUS_IN_PROGRESS,
      STATE_IN_HOUSE_SERVICE,
      inHouseFormBottomActions,
      FORM_BUTTON_SAVE_AS_DRAFT,
      FORM_BUTTON_EMAIL_SUPPLIER,
      FORM_BUTTON_QUOTE_RECEIVED,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      FORM_BUTTON_CREATE_SERVICE_ORDER,
      SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE,
    }
  },
}
</script>
