<template>
  <div>
    <div>
      <error-alert
        :fields="{}"
        :error="onError"
      />
      <!--   Form Body   -->
      <b-form>
        <validation-observer
          ref="requestServiceQuoteForm"
        >
          <asset class="mb-1" />
          <div
            class="bg-white"
            style="border-radius: 5px 5px 0 0; padding-top: 12px"
          >
            <div style="padding: 0 10px">
              <h3 class="font-weight-bolder mb-1">
                {{ $t(SERVICE_ORDER_INFORMATION_TITLE) }}
              </h3>
            </div>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="service_order_information_service_type mb-0"
            >
              <b-form-radio
                v-for="radio in serviceTypeFields"
                :id="radio.value"
                :key="radio.value"
                v-model="serviceTypeValue['value']"
                :aria-describedby="ariaDescribedby"
                :name="radio.name"
                :value="radio.value"
                @change="onServiceTypeChange(radio.value)"
              >
                {{ $t(radio.title) }}
              </b-form-radio>
            </b-form-group>
          </div>
          <div>
            <send-out-for-services v-if="serviceTypeValue['value'] === SERVICE_TYPE_SEND_OUT_SERVICE" />
            <in-house-service v-if="serviceTypeValue['value'] === SERVICE_TYPE_IN_HOUSE_SERVICE" />
          </div>
        </validation-observer>
      </b-form>
    </div>
    <form-bottom :form-refs="$refs" />
    <!--    <b-s-warning-modal-->
    <!--      ref="confirm-modal"-->
    <!--      @onNextTick="onNextTick"-->
    <!--    />-->
  </div>
</template>

<script>
// Components
import { BForm, BFormGroup, BFormRadio } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import serviceOrdersModule from '@/store/settings/orders/service-orders'
import config from './config'
import generalConfig from '../../../config'
import FormBottom from './FormBottom.vue'
import SendOutForServices from './components/SendOutForServices.vue'
import InHouseService from './components/InHouseService.vue'
import Asset from '../../common/Asset.vue'

export default {
  name: 'Form',
  components: {
    Asset,
    BForm,
    ErrorAlert,
    FormBottom,
    BFormGroup,
    BFormRadio,
    ValidationObserver,
    SendOutForServices,
    InHouseService,
  },
  data() {
    return {
      onSubmit: false,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order ?? {}
    },
    serviceTypeValue() {
      return this.$store.state[this.MODULE_NAME].serviceType
    },
    serviceOrders() {
      return this.$store.state[this.MODULE_NAME].serviceOrdersForm
    },
    serviceOrderInformationSupplierID() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationForm.customer_id
    },
    isOrderTypeSendOutForServices() {
      return this.SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE === this.order.service_type || this.SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE === this.order.service_type
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    enableInHouse() {
      const isInHouse = this.$store.state[this.MODULE_NAME_SET_ORDERS].serviceOrdersForm?.service_orders_enable_in_house
      return isInHouse ? (/true/).test(isInHouse) : false
    },
    serviceTypeFields() {
      const fields = this.serviceType
      return this.enableInHouse
        ? fields
        : fields.filter(({ value }) => value !== this.SERVICE_TYPE_IN_HOUSE_SERVICE)
    },
  },
  watch: {
    serviceOrderInformationSupplierID(val) {
      if (val) return

      this.$refs.requestServiceQuoteForm.reset()
    },
  },
  mounted() {
    this.retrySetOrders()
  },
  methods: {
    onServiceTypeChange(type) {
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_TYPE_ON_CHANGE`, type)
    },
    retrySetOrders() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME_SET_ORDERS}/getServiceOrders`).then(res => {
        const { data } = res.data
        const obj = {}
        data.forEach(({ key, value }) => {
          obj[key] = value
        })
        this.$store.commit(`${this.MODULE_NAME_SET_ORDERS}/SET_FORM`, obj)
        this.$store.commit(`${this.MODULE_NAME_SET_ORDERS_CLONE}/SET_ORDER_SERVICE_ORDERS_CLONE`, obj)
      })
    },

  },
  setup() {
    const {
      serviceType,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      storeMutationsList,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      SERVICE_ORDER_INFORMATION_TITLE,
      SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE,
      SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE,
      MODULE_NAME_SET_ORDERS,
      MODULE_NAME_SET_ORDERS_CLONE,
    } = config()

    const { quoteStatusBar } = generalConfig()

    if (!store.hasModule(MODULE_NAME_SET_ORDERS)) store.registerModule(MODULE_NAME_SET_ORDERS, serviceOrdersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_SET_ORDERS)) store.unregisterModule(MODULE_NAME_SET_ORDERS)
    })

    return {
      serviceType,
      MODULE_NAME,
      quoteStatusBar,
      MODULE_NAME_CLONE,
      storeMutationsList,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
      SERVICE_ORDER_INFORMATION_TITLE,
      SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE,
      SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE,
      MODULE_NAME_SET_ORDERS,
      MODULE_NAME_SET_ORDERS_CLONE,
    }
  },
}
</script>
