<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(DISPATCH_AND_RETURN_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['dispatch_warehouse_id']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          :is-editable="true"
          v-bind="getProps(field, dispatchAndReturnFields)"
        />
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['return_warehouse_id', 'internal_dispatch_notes']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          v-bind="getProps(field, dispatchAndReturnFields)"
        />
      </b-col>
      <b-col md="6">
        <component
          :is="dispatchAndReturnFields[field].type"
          v-for="field in ['dispatch_method_id','return_method_id']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="dispatchAndReturn[field]"
          v-bind="getProps(field, dispatchAndReturnFields)"
          class="h-auto"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { getProps } from '@/@core/utils/utils'
import config from '../config'

export default {
  name: 'DispatchAndReturn',
  components: {
    BRow,
    BCol,
  },
  computed: {
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    currentWarehouse() {
      return this.$store.state.app.warehouse
    },
    returnWarehouse() {
      return this.$store.state.app.warehouse
    },
    dispatchList() {
      return this.$store.state.listModule.dispatchAndReturnMethodsList
    },
    returnList() {
      return this.$store.state.listModule.dispatchAndReturnMethodsList
    },
  },
  watch: {
    dispatchList(obj) {
      if (this.filteredDispatches(obj)) {
        const data = this.filteredDispatches(obj)
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
          ...this.dispatchAndReturn,
          dispatch_method_id: {
            id: data.id,
            name: data.name,
          },
        })
      }
    },
    returnList(obj) {
      if (this.filteredDispatches(obj)) {
        const data = this.filteredDispatches(obj)
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
          ...this.dispatchAndReturn,
          return_method_id: {
            id: data.id,
            name: data.name,
          },
        })
      }
    },
    currentWarehouse(obj) {
      if (this.dispatchAndReturn?.dispatch_warehouse_id) return
      this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
        ...this.dispatchAndReturn,
        dispatch_warehouse_id: {
          id: obj.id,
          label: obj.label,
        },
      })
    },
    returnWarehouse(obj) {
      if (this.dispatchAndReturn?.return_warehouse_id) return
      this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
        ...this.dispatchAndReturn,
        return_warehouse_id: {
          id: obj.id,
          label: obj.label,
        },
      })
    },
  },
  methods: {
    filteredDispatches(obj) {
      const data = obj.filter(item => item.is_default)
      return data[0]
    },
    onReturnContactIsDifferent(bool) {
      this.isEditable = !bool
    },
    onChangeDispatchContact(item) {
      const { cell_no_object, email } = item
      const { formatInternational } = JSON.parse(cell_no_object)

      this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
        ...this.dispatchAndReturn,
        dispatch_phone: formatInternational,
        dispatch_email: email,
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      dispatchAndReturnFields,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      dispatchAndReturnRequiredFields,
      DISPATCH_AND_RETURN_FORM_TITLE,
    } = config()
    const isEditable = ref(true)

    return {
      getProps,
      isEditable,
      MODULE_NAME,
      dispatchAndReturnFields,
      DISPATCH_AND_RETURN_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      dispatchAndReturnRequiredFields,
    }
  },
}
</script>
