<template>
  <div>
    <service-order-information class="mb-1" />
    <dispatch-and-return />
  </div>
</template>

<script>
import ServiceOrderInformation from './ServiceOrderInformation.vue'
import DispatchAndReturn from './DispatchAndReturn.vue'

export default {
  name: 'SendOutForServices',
  components: {
    DispatchAndReturn,
    ServiceOrderInformation,
  },
}
</script>

<style scoped>

</style>
