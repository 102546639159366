<template>
  <div class="form-wrapper">
    <b-row>
      <b-col cols="6" />
      <b-col cols="6">
        <component
          :is="serviceOrderInformationFields[field].type"
          v-for="field in ['internal_order_reference_in_house']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="serviceOrderInformationForm[field]"
          class="h-auto"
          v-bind="getProps(field, serviceOrderInformationFields)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getProps } from '@/@core/utils/utils'
import config from '../config'

export default {
  name: 'InHouseService',
  components: {
    BRow, BCol,
  },
  computed: {
    serviceOrderInformationForm() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationForm
    },
  },
  setup() {
    const { serviceOrderInformationFields, MODULE_NAME } = config()

    return {
      getProps,
      MODULE_NAME,
      serviceOrderInformationFields,
    }
  },
}
</script>
